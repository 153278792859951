
import {computed, defineComponent, h, ref, watch} from "vue";
import {
  IonBackButton,
  IonButtons,
  IonCard, IonCardContent, IonCardHeader, IonCol,
  IonContent,
  IonGrid,
  IonHeader, IonItem, IonItemDivider, IonLabel, IonList,
  IonPage, IonRow, IonSearchbar,
  IonTitle,
  IonToolbar, isPlatform
} from "@ionic/vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {Material} from "@/domain/model/material/material.model";
import StockItemRecordComponent from "@/app/view/stock/details-record/StockItemRecordComponent.vue";
import {StockRecord, StockTimeline} from "@/domain/model/stock/stock.model";
import _, {map} from "lodash";
import {useStockApi} from "@/domain/service/api/stock.api";
import {formatDate} from "@/domain/helper/date.helper";
import {NButton, NDataTable, NSpace, NTag} from "naive-ui";

export default defineComponent({
  name: "StockItemRecordPage",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonCard,
    IonCardHeader,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    // IonLabel,
    IonCardContent,
    IonSearchbar,
    IonList,
    IonItemDivider,
    StockItemRecordComponent,
    NSpace,
    NDataTable,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const stockApi = useStockApi();
    const id = ref(route.params.id);
    const material = computed<Material>(() => store.getters.materialById(id.value));
    const timeLine = computed<StockTimeline>(() => store.getters.stockTimelineByRef(material.value.ref));

    const searchWord = ref<string>('');
    // const maxResults = ref(25);
    const searchStockRecord = (stockRecord: StockRecord, word: string): boolean =>
        String(stockRecord.amount).includes(word.toLowerCase()) ||
        formatDate(stockRecord.createdAt, "DD-MM-YYYY").includes(word.toLowerCase()) ||
        stockRecord.concept.toLowerCase().includes(word.toLowerCase());
    const filterStockRecord = (stockRecords: StockRecord[], word: string): StockRecord[] =>
        _.filter(stockRecords, (stockRecordNode: StockRecord) => searchStockRecord(stockRecordNode, word));
    const sortStockRecords = (stockRecords: StockRecord[]): StockRecord[] => _.orderBy(stockRecords, ['createdAt'],[ 'desc']);
    const stockRecordList = computed<StockRecord[]>(() => sortStockRecords(filterStockRecord(timeLine.value?.timeline, searchWord.value))
        // .slice(0, maxResults.value)
    );
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));

    if (material.value && material.value.ref)
      stockApi.fetchTimeline(material.value.ref);
    const data = ref<any>([]);
    const replaceData = () => {
      data.value =
          map(stockRecordList.value, (stockNode: StockRecord) => {
            return {
              id: stockNode.id,
              date: formatDate(stockNode.createdAt, "DD-MM-YYYY"),
              dateTime: formatDate(stockNode.createdAt, "HH:mm"),
              concept: stockNode.concept,
              amount: stockNode.amount.toLocaleString('es'),
            };
          });
    };
    watch(stockRecordList, () => replaceData(), {immediate: true});
    watch(material, (material: Material) => {
          if (!material)
            return;

          stockApi.fetchTimeline(material.ref);
        }
    );

    return {
      data,
      columns: [
        {
          title: "Fecha",
          key: "date",
          sorter: 'default'
        },
        {
          title: "Hora",
          key: "dateTime",
          sorter: 'default'
        },
        {
          title: "Concepto",
          key: "concept",
          sorter: 'default'
        },
        {
          title: "Cantidad",
          key: "amount",
          sorter: 'default'
        },
      ],
      stockRecordList,
      material,
      timeLine,
      searchWord,
      isTablet,
    };
  }
})
