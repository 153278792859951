
import {computed, defineComponent, ref, watch} from "vue";
import {IonCol, IonGrid, IonInput, IonItem, IonLabel, IonRow, isPlatform} from "@ionic/vue";
import {StockRecord} from "@/domain/model/stock/stock.model";
import {formatDate} from "@/domain/helper/date.helper";

export default defineComponent({
  name: "StockItemRecordComponent",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,

  },
  props: {
    timeline: Object,
  },
  setup(prop: any) {
    const timelineProp = ref<StockRecord>(prop.timeline);
    const endDate = formatDate(timelineProp.value.createdAt, "DD-MM-YYYY");
    const endHour = formatDate(timelineProp.value.createdAt, "HH:mm");
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));

    return {
      timelineProp,
      endDate,
      isTablet,
      endHour,
    };
  }
})
